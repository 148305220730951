'use strict';

const MIN_NUMBER_OF_PARAMETERS = 8;

const $container = document.getElementById('js-cwpp-button');
const $staticImage = document.getElementById('js-cwpp-static-image');

/**
 * @returns {void}
 */
function showPayPalStaticImageButton() {
    if ($staticImage) {
        $staticImage.classList.remove('d-none');
    }
}

/**
 * @returns {boolean} - returns true if container exists false if not
 */
function isContainerExist() {
    if (!$container) {
        showPayPalStaticImageButton();

        return false;
    }

    return true;
}

/**
 * @param {*} callback - a callback function for onload event
 * @returns {void}
 */
function addConnectWithPayPalScript(callback) {
    if (!isContainerExist()) {
        return;
    }

    const script = document.createElement('script');

    script.src = $container.getAttribute('data-cwpp-sdk');

    script.onload = () => {
        if (callback && typeof callback === 'function') {
            callback();
        }
    };

    script.onerror = showPayPalStaticImageButton;

    document.body.appendChild(script);
}

/**
 * Connect with PayPal
 * @link {https://developer.paypal.com/docs/log-in-with-paypal/integrate/generate-button}
 */
function initConnectWithPayPal() {
    if (!isContainerExist()) {
        return;
    }

    const parameters = JSON.parse($container.getAttribute('data-parameters'));

    if (!(window.paypal && 'use' in window.paypal && Object.keys(parameters).length > MIN_NUMBER_OF_PARAMETERS)) {
        showPayPalStaticImageButton();

        return;
    }

    window.paypal.use(['login'], function(login) {
        login.render(parameters);
    });
}

/**
 * Function which initiate PayPal functionality on the Login Page
 */
function init() {
    addConnectWithPayPalScript(initConnectWithPayPal);
}

module.exports = {
    init
};
